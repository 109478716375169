import React, { useEffect, useState } from 'react'
import './Sidebar.css'
import completeImg from "../../images/complete.png" 
import { getActiveStageIndex, getStageListWithIndex } from '../../utils/customMethods'
const userStageList = [
  {stage:"KYC Pending"},
  {stage:"Approval Pending"},
  {stage:"Select Plan"},
  {stage:"Agreement generation/Signoff"},
  {stage:"Payment Collection"},
  {stage:"NACH Registration"},
  {stage:"Loan Disbursed"},
]
const Sidebar = ({stage}) => {
  const [stageList,setStageList] = useState(getStageListWithIndex(userStageList));
  const [activeStageIndex,setActiveStageIndex] = useState(-1);
  useEffect(() => {
    if(stage){
      const index = getActiveStageIndex(stage);
     setActiveStageIndex(index)
    }
    
    //  const index = getActiveStageIndex("KYC Pending");
    //  setActiveStageIndex(index)
   
  },[stage])
  return (
    <div className='sidebar'>
      <div className='stepWrapper'>
    {
      stageList.map((ele,i) => {
        return(
          <div key={`multistepper${i}`} className={"stepBlock" + (activeStageIndex > ele.index ? " selected" : "")+(activeStageIndex === ele.index ? " "+"activestage":"")}>
          <div className="circleWrapper" >
              {activeStageIndex > ele.index ? <img src={completeImg}/>
              :<div className="circle"></div>}
          </div>
          <span>{ele.stage}</span>
          </div>
        )
      })
    } 
    


      
        
        
        </div>
    </div>
  )
}

export default Sidebar