import React, { useEffect, useState } from 'react'
import './VerifyMobile.css'
import TermsCondition from '../TermsCondition/TermsCondition'
import Loader from '../Loader/Loader';
import { useParams } from 'react-router-dom';
import CustomErrorPage from '../CustomErrorPage/CustomErrorPage';
import Navbar from '../Navbar/Navbar';
import ApiCallLoader from '../ApiCallLoader/ApiCallLoader';
import OTPInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { captureTimeStamp, sendMobileOtp, verifyMobileOtp } from '../../action/document';
import SuccessKyc from '../SucessKyc/SuccessKyc';


let interval;
const VerifyMobile = () => {
  const [loading,setLoading] = useState(false);
  const [showUIToUser,setShowUIToUser] = useState(true);
  const [err,setErr] = useState("");
  const params = useParams();
  const [appId,setAppId] = useState(params?.id || "");
  const [apiLoader,setApiLoader] = useState(false);
  const [otp,setOtp] = useState("");
  const [mobileConsent,setMobileConsent] = useState(false);
  const [resendStart,setResendStart] = useState("");
  const [showResendOtpBtn,setShowResendOtpBtn] = useState(false);
  const [otpVerified,setOtpVerified] = useState(false);

  useEffect(()=>{
    if(resendStart === 0){
     clearInterval(interval);
    }
  },[resendStart])


  useEffect(() => {
    const sendData = async() => {
      const sendData = {
        application_id:appId
      }
       const res = await captureTimeStamp(sendData)
    }
    sendData();
  },[])
 
const handleverifyOtp = async() => {
  const sendData = {
    application_id:appId,
    otp:otp
  }
  setApiLoader(true)
  const res = await verifyMobileOtp(sendData);
  setApiLoader(false)
  if(res.status === "success"){
    toast.success(res.message)
    clearInterval(interval);
    setResendStart(0);
    setOtpVerified(true)
  }else{
    toast.error(res.message);
    // setErr(res.message)
  }
}

const hadleEnterKeyDown = () => {

}

const handleSendOtp = async() => {
  if(!mobileConsent){
    alert("Please tick the consent")
     return;
  }
  const sendData = {
    app_id:appId,
  }
  clearInterval(interval)
  setApiLoader(true);
  const res = await sendMobileOtp(sendData);
  setApiLoader(false)
  if(res.status === "success"){
  toast.success(res.message)
  setResendStart(59);
  setShowResendOtpBtn(true)
  setOtp("")
  interval = setInterval(() => {
    setResendStart(prev =>prev === 0 ? 0 :prev-1)
 }, 1000);
}else{
  toast.error(res.message);
  setErr(res.message)
}
}

  return (
    loading ? 
    <Loader/> :
     showUIToUser === false && loading === false && err ?
     <CustomErrorPage/> :
     otpVerified ? <SuccessKyc message={"Phone Verified Successfully"}/> :
     showUIToUser === true &&
    <>
     <Navbar />
    <div className='side-main-container'>
      {/* <Sidebar/> */}
     <div className="main-content">
     <>
     {apiLoader && <ApiCallLoader/>}
     {/* <Navbar/> */}
     <div className='verify-mobile-comp'>
      <h3 className='vm-heading-text'>Verify Mobile</h3>
     <TermsCondition/>
     <div className='mb-consent-check-cont'>
      <input disabled={showResendOtpBtn} type='checkbox' checked={mobileConsent} style={{marginTop:'3px'}} onChange={(e)=>setMobileConsent(e.target.checked)}/>
      <p>By clicking on ACCEPT and entering OTP, I confirm having applied for the loan and verify all personal details in the application. Further I confirm having read, understood and agree to the Terms and Conditions, Privacy Policy and Disclaimer.</p>
    </div>
   
{ !showResendOtpBtn ?    <button
         className={(resendStart > 0 && resendStart <=59 ) || apiLoader ?"disable-btn verify-btn" :"enable-btn verify-btn"}
          onClick={handleSendOtp}
          disabled={(resendStart > 0 && resendStart <=59 ) || apiLoader ? true : false }
          >Send OTP</button>
          :  
          <p className='resend resendotpsec' ><button className={`resendoTp ${(resendStart > 0 && resendStart <=59) || apiLoader ?"disable-resend":''}`} onClick={handleSendOtp} disabled={(resendStart > 0 && resendStart <=59) || apiLoader ? true: false}>Resend</button> OTP in 00:{resendStart < 10 ? `0${resendStart}` : resendStart} seconds</p>
          }


    {showResendOtpBtn && <div className="otp-cont">
                 <h4 className="otp-sent-text">Enter OTP</h4>
                 <div className="optverify">
                   <OTPInput
                     value={otp}
                     // onChange={(e) => this.setState({otp:e.target.value })}
                     onChange={(value) => setOtp(value)}
                     numInputs={6}
                     shouldAutoFocus
                     inputType="number"
                     renderSeparator={<span></span>}
                     renderInput={(props) => (
                       <input {...props} onKeyDown={hadleEnterKeyDown} />
                     )}
                   />
              
                 </div>
                
                 <button
                 disabled={otp.length < 6 || apiLoader? true : false}
                 className={
                  otp.length < 6 || apiLoader
                  ? "disable-btn verify-btn"
                     : "enable-btn verify-btn"
                   }
                   onClick={handleverifyOtp}
                   >
                 Verify OTP
               </button>
   
               </div>}
     </div>
     </>
     </div>
    </div>
    </>
  )
}

export default VerifyMobile