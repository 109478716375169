import React, { useEffect, useState } from 'react'
import './SuccessKyc.css'
import Navbar from '../Navbar/Navbar'
import successLogo from '../../images/success.svg'
import { toast } from 'react-toastify'
import { getUserStatus } from '../../action/document'
import ApiCallLoader from '../ApiCallLoader/ApiCallLoader'
import { useNavigate } from 'react-router-dom'
let popupCheckInterval;

let interval;
const SuccessKyc = ({message,showNextStageButton,rediectUrl,stage,setNachStatus,appId,transId,setStageMessage,nachStatusforPnach,getStatus,setShowNextStageButton,nachStatus}) => {
  const [apiLoading,setApiLoading] = useState(false);
  const [timer,setTimer] = useState(30);
  const navigate = useNavigate();
  let localStage = stage;

  
  
  const handleRedirectToNach = async() => {
     if(stage === "Payment Collection"){
      window.location.reload();
      return
     }

     if(stage=="Nach Register" && (nachStatusforPnach == "ENach Failed" || nachStatusforPnach == "PNach Pending" || nachStatusforPnach === "PNach Completed")){
      // toast(`Redirecting to Pnach stage.`)
    
      setTimeout(() => {
        navigate(`/cis/${appId}/${transId}`);
      }, 1000);
      return;
     }

     if(stage=="Pre-disbursal" && nachStatus == "rejected" ){
      // toast(`Redirecting to Pnach stage.`)
      setTimeout(() => {
       navigate(`/cis/${appId}/${transId}`);
      }, 1000);
      return;
     }
    if(rediectUrl){
      openPopUp();
      // toast(`Redirecting to ${localStage} stage.`)
      // setTimeout(() => {
      //   window.location.href = rediectUrl;
      // }, 1000);
    }
    return;
    // setApiLoading(true);
    //     let res = await getUserStatus(appId,transId);
    //     setApiLoading(false)
    //     if(res.status === "success"){
    //       // res.metadata.Status_NACH = "confirmed"
    //       if(res?.metadata?.Status_NACH === "confirmed"){
    //         setNachStatus("confirmed");
    //           return;
    //       }
    //       if(res.stage === "payment_pending" || res.stage === "nach_pending"){
    //         let local = res.stage === "payment_pending" ? "Payment Collection" : res.stage === "nach_pending" ? "NACH Registration" : res.stage;
    //         if(!res.url){
    //           toast.error("Failed to load url");
    //           return;
    //         }
    //         toast(`Redirecting to ${local} stage.`)
    //            setTimeout(() => {
    //              window.location.href = res.url;
    //            }, 1000);

    //      }
    //      else if(res.stage === "Product Selection" || res.stage === "Select Plan" || res.stage === "KYC Verification" || res.stage === "Select lender"  || res.stage === "Lender's Approval Pending"){
    //        setStageMessage(`Application is on ${res.stage} stage`)
    //      }else{
    //           setStageMessage("Thank you for completing process successfully. ")
    //      }
         
    //     }else{
    //      //  setErr(res.message)
    //       toast.error(res.message);
    //     }

       }

       useEffect(() => {
        if(stage === "Payment Collection"){ 
          interval =  setInterval(() => {
              setTimer(prev => prev === 0 ? prev : prev-1)
            }, 1000);
        }
       },[stage])

       useEffect(() => {
        if(timer === 0){
          clearInterval(interval);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
       },[timer])
       
    //    else if(stage && rediectUrl){
      
    //   toast(`Redirecting to ${localStage} stage.`)
    //            setTimeout(() => {
    //              window.location.href = rediectUrl;
    //            }, 1000);
    // }else{
    //   toast.error("Failed to load url");
    // }
        // }


        const openPopUp = async() => {
          const razorpayUrl = rediectUrl; 
          // const razorpayUrl = "https://rzp.io/i/0gyOY6UbtO"; 

              const width = window.screen.width * 0.9;
              const height = window.screen.height * 0.9;
              
              // Calculate the position to center the popup
              const left = (window.screen.width - width) / 2;
              const top = (window.screen.height - height) / 2;
      
      // Open the window at the calculated position
      const popupWindow = window.open(razorpayUrl,"MyTitle", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=${width}, height=${height}, top=${top}, left=${left}`);
      // const popupWindow  = window.open('popup.html', 'popupWindow', 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top);
       
    
          function closePopup() {
            if (popupWindow && !popupWindow.closed) {
              popupWindow.close();
            }
          }
        
          // Function to check the URL of the popup window
        async function checkPopupUrl() {
          if (popupWindow && !popupWindow.closed) {
            try {
                 const res = await getUserStatus(appId,transId);
                 if(res.status === "success"){
                  if(res.stage === "nach_pending"){
                    if(res?.metadata?.nach_status == "ENach Failed" || res?.metadata?.nach_status == "PNach Pending" || res?.metadata?.nach_status === "PNach Completed"){
                        // toast(`Redirecting to Pnach stage.`)
                        setTimeout(() => {
                          navigate(`/cis/${appId}/${transId}`);
                        }, 1000);
                        closePopup();
                        return;
                       }
                  // just check that if it is on nach then do nothing
                  }else{
                    if(res.stage=="Pre-disbursal"){
                       if(res?.metadata?.Status_NACH == "rejected"){
                            // toast(`Redirecting to Pnach stage.`)
                            closePopup();
                            clearInterval(popupCheckInterval)
                            //  navigate(`/cis/${appId}/${transId}`);
                            window.location.href = window.location.origin+`/cis/${appId}/${transId}`
                            // window.location.reload()
                           }else if(res?.metadata?.Status_NACH == "confirmed"){
                            closePopup();
                            setStageMessage("Thank you for completing process successfully.")
                           }
                    }else{
                         // if the stage moves from nach to other then close nach popup and show message
                       closePopup();
                       setStageMessage("Thank you for completing process successfully.")
                    }
                  }
                 }
            } catch (e) {
          
            }
          }
          }
        
          // Check the status of the popup window at intervals
           popupCheckInterval = setInterval(function () {
            if (popupWindow.closed) {
              clearInterval(popupCheckInterval);
            } else {
              checkPopupUrl();
            }
          }, 5000);
      }


  return (
    <>
    <Navbar />
    <div className='side-main-container'>
      {/* <Sidebar/> */}
     <div className="main-content">
     <>
     { apiLoading && <ApiCallLoader/>}
      {/* <Navbar/> */}
      <div className='kyc-success-cont'>
        <img src={successLogo} alt='success'/>
        <p>{stage === "Payment Collection" ? "Your payment is being processed." : message ? message : ''}</p>
       { showNextStageButton &&  
       <button 
       className={apiLoading ? "disable-btn verify-btn":"enable-btn verify-btn"}
        onClick={handleRedirectToNach}
        disabled ={apiLoading? true : false}
        // disabled={(resendStart > 0 && resendStart <=59 ) || apiLoader ? true : false }
        > {stage === "Payment Collection" ?
         <>
         <div className='refresh-loader-cont'>
        <span class="refresh-loader"><span>{timer < 10 ?`0${timer}`:timer}</span></span>
        <p class="refresh-text">Page will refresh after {timer < 10 ?`0${timer}`:timer}:00</p>
        </div>
         </> 
         :`Continue to ${localStage == "Nach Register" ? "NACH Registration" : localStage}`}</button>}
    </div>
    </>
     </div>
    </div>
    </>
  )
}

export default SuccessKyc