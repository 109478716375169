import React from 'react'
import Navbar from '../Navbar/Navbar'
import { getUserStatus } from '../../action/document';
import { useNavigate } from 'react-router-dom';
let popupCheckInterval;
const NachRegistaration = ({ message, url,setStageMessage,appId,transId }) => {

   const navigate = useNavigate();

    const handleRedirectToNach = () => {
        if (url) {
                const razorpayUrl = url;
                    const width = window.screen.width * 0.9;
                    const height = window.screen.height * 0.9;
                    
                    // Calculate the position to center the popup
                    const left = (window.screen.width - width) / 2;
                    const top = (window.screen.height - height) / 2;
            
            // Open the window at the calculated position
            const popupWindow = window.open(razorpayUrl,"MyTitle", `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, width=${width}, height=${height}, top=${top}, left=${left}`);
            // const popupWindow  = window.open('popup.html', 'popupWindow', 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top);
             
          
                function closePopup() {
                  if (popupWindow && !popupWindow.closed) {
                    popupWindow.close();
                  }
                }
              
                // Function to check the URL of the popup window
              async function checkPopupUrl() {
                  if (popupWindow && !popupWindow.closed) {
                    try {
                         const res = await getUserStatus(appId,transId);
                         if(res.status === "success"){
                          if(res.stage === "nach_pending"){
                            if(res?.metadata?.nach_status == "ENach Failed" || res?.metadata?.nach_status == "PNach Pending" || res?.metadata?.nach_status === "PNach Completed"){
                                // toast(`Redirecting to Pnach stage.`)
                                setTimeout(() => {
                                  navigate(`/cis/${appId}/${transId}`);
                                }, 1000);
                                closePopup();
                                return;
                               }
                          // just check that if it is on nach then do nothing
                          }else{
                            if(res.stage=="Pre-disbursal"){
                               if(res?.metadata?.Status_NACH == "rejected"){
                                    // toast(`Redirecting to Pnach stage.`)
                                    closePopup();
                                    clearInterval(popupCheckInterval)
                                    //  navigate(`/cis/${appId}/${transId}`);
                                    window.location.reload()
                                    return;
                                   }else if(res?.metadata?.Status_NACH == "confirmed"){
                                    closePopup();
                                    setStageMessage("Thank you for completing process successfully.")
                                   }
                            }else{
                                 // if the stage moves from nach to other then close nach popup and show message
                               closePopup();
                               setStageMessage("Thank you for completing process successfully.")
                            }
                          }
                         }
                    } catch (e) {
                  
                    }
                  }
                }
              
                // Check the status of the popup window at intervals
                 popupCheckInterval = setInterval(function () {
                  if (popupWindow.closed) {
                    clearInterval(popupCheckInterval);      
                  } else {
                    checkPopupUrl();
                  }
                }, 5000);
            }
        }

        return (
            <>
                <Navbar />
                <div className='message-only-conatiner'>
                    <h2>{message}</h2>
                    <button
                        className={"enable-btn verify-btn"}
                        onClick={handleRedirectToNach}
                    // disabled ={apiLoading? true : false}
                    >Continue to Nach Registration</button>
                </div>
            </>
        )
    }

    export default NachRegistaration