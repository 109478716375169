import React from 'react'
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import './TermsCondition.css'
import policyImg from "../../images/policyImg.jpg"


import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {BsFillEyeFill,} from 'react-icons/bs'
import {BiSolidDownload} from 'react-icons/bi'


const TermsDocUrl = "https://los-mule-vkyc-rec.s3.us-west-2.amazonaws.com/EdvnzPhoneVerificationDocs/Application+T%26Cs_V1.0_05122023.pdf";
const PolicyDocUrl = "https://los-mule-vkyc-rec.s3.us-west-2.amazonaws.com/EdvnzPhoneVerificationDocs/Privacy+Policy_V.1_Final+version_05122023.pdf";
const DisclaimerDocUrl = "https://los-mule-vkyc-rec.s3.us-west-2.amazonaws.com/EdvnzPhoneVerificationDocs/Disclaimer_V1.0_05122023.pdf";

const TermsCondition = () => {
  const [termsOpen, setTermsOpen] = React.useState(false);
  const [policysOpen, setPolicysOpen] = React.useState(false);
  const [disclaimerOpen, setDisclaimerOpen] = React.useState(false);

  const handleClick = (id) => {
    if(id===1){
      setTermsOpen(!termsOpen);
    }else if(id===2){
      setPolicysOpen(!policysOpen)
    }else if(id===3){
       setDisclaimerOpen(!disclaimerOpen)
    }
  };

 

  return (
    <div>
           <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
    >
      <ListItemButton onClick={() => handleClick(1)}>
        <ListItemText className='vm-labels' primary="Terms & Conditions" />
        {termsOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={termsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
          <iframe src={TermsDocUrl} height="360" width="100%" title="Iframe Example" ></iframe>
          <div className="agree-cont" style={{width:"100%"}}>
             <img src={policyImg} className="agree-img"/>
             <div className="icons-cont">
            <a href={TermsDocUrl} target="_blank" className="agree-icons">
            <BsFillEyeFill size={32} color="#2596be"/>
            </a>
            <a href={TermsDocUrl} target="_blank" className="agree-icons">
             <BiSolidDownload size={32} color="#2596be"/>
            </a>
             </div>
         </div>
          </ListItemButton>
        </List>
      </Collapse>


      <ListItemButton onClick={() => handleClick(2)}>
        <ListItemText className='vm-labels' primary="Privacy Policy" />
        {policysOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={policysOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
          <iframe src={PolicyDocUrl} height="360" width="100%" title="Iframe Example" ></iframe>
          <div className="agree-cont" style={{width:"100%"}}>
             <img src={policyImg} className="agree-img"/>
             <div className="icons-cont">
            <a href={PolicyDocUrl} target="_blank" className="agree-icons">
            <BsFillEyeFill size={32} color="#2596be"/>
            </a>
            <a href={PolicyDocUrl} target="_blank" className="agree-icons">
             <BiSolidDownload size={32} color="#2596be"/>
            </a>
             </div>
         </div>
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton onClick={() => handleClick(3)}>
        <ListItemText className='vm-labels' primary="Disclaimer" />
        {disclaimerOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={disclaimerOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
          <iframe src={DisclaimerDocUrl} height="360" width="100%" title="Iframe Example" ></iframe>
          {/* for mobile view  */}
          <div className="agree-cont" style={{width:"100%"}}>
             <img src={policyImg} className="agree-img"/>
             <div className="icons-cont">
            <a href={DisclaimerDocUrl} target="_blank" className="agree-icons">
            <BsFillEyeFill size={32} color="#2596be"/>
            </a>
            <a href={DisclaimerDocUrl} target="_blank" className="agree-icons">
             <BiSolidDownload size={32} color="#2596be"/>
            </a>
             </div>
         </div>
          </ListItemButton>
        </List>
      </Collapse>
    </List>
    </div>
  )
}

export default TermsCondition