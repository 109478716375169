import React from 'react'
import Navbar from '../Navbar/Navbar';
import './PaymentDetails.css'
import { toast } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar';

const PaymentDetails = ({paymentDetails,stage,redirectUrl,sidebarStage}) => {
    const handleRedirect = () => {
      toast(`Redirecting to ${stage} stage.`)
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 1000);
    }
    const {advanceEmi,processingFee,downPayment,totalUpfrontAmount} = paymentDetails
  return (
    <>
    <Navbar />
    <div className='side-main-container'>
      <Sidebar
      stage={sidebarStage}
      />
     <div className="main-content">
     <>
        {/* <Navbar/> */}
    <div className='payment-details-container'>
            <div>
            <h2 className='pd-header'>Payment Details</h2>
        <div className='payment-detail'>

   {advanceEmi && <div className='pd-key-value'>
    <span className='pd-key'>Advance EMI </span> 
    <span className='pd-value'>₹ {advanceEmi}</span>
   </div>
}
  {processingFee  && <div className='pd-key-value'>
    <span className='pd-key'>Processing Fee</span> 
   <span className='pd-value'>₹ {processingFee}</span>
   </div>}
   
  {downPayment && <div className='pd-key-value'>
    <span className='pd-key'>Down Payment</span>
    <span className='pd-value'>₹ {downPayment}</span>
    </div>}

  {totalUpfrontAmount &&  <div className='pd-key-value'>
    <span className='pd-key'>Total Upfront Payment</span>
    <span className='pd-value'>₹ {totalUpfrontAmount}</span>
    </div>
}
</div>


<button 
className="enable-btn verify-btn pd-payment-btn"
onClick={handleRedirect}
//   disabled={(resendStart > 0 && resendStart <=59 ) || apiLoader ? true : false }
>Continue to Payment Collection</button>
            </div>
        </div>
    </>
     </div>
    </div>
    </>
  )
}

export default PaymentDetails