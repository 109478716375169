import axios from "axios";
const axiosInstance = axios.create({baseURL:process.env.REACT_APP_URL})
// const axiosInstance = axios.create({baseURL:"http://localhost:5000/okyc"})


export const getUserStatus = async (appId,transId) => {
    try {
        const url = transId ? `/cis/${appId}/${transId}` : `/cis/${appId}`
     const {data} = await axiosInstance.get(url,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        if(error?.response?.status === 404 || error.message === "Network Error"){
            return {
                status: 'failed', message: 'Failed to load data' } 
            }
        return error.response.data; 
    }
}
export const VerifyAdharNumber = async (adharData) => {
    try {
     const {data} = await axiosInstance.post("/okyc/sendotp",adharData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY,
        }
     })
     return data;
    } catch (error) {
        return error.response.data; 
    }
}

export const VerifyAdharOtp = async (adharData) => {
    try {
     const {data} = await axiosInstance.post("okyc/verifyotp",adharData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return error.response.data; 
    }
}



export const sendAgreementOtp = async (adharData) => {
    try {
     const {data} = await axiosInstance.post("/agreement/sendotp",adharData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return error.response.data; 
    }
}

export const VerifyAgreementOtp = async (adharData) => {
    try {
     const {data} = await axiosInstance.post("agreement/verifyotp",adharData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY,
        }
     })
     return data;
    } catch (error) {
        return error.response.data; 
    }
}


export const sendMobileOtp = async ({app_id}) => {
    try {
     const {data} = await axiosInstance.get(`verification/mobile/sendOtp/${app_id}`,{
        headers:{
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return error.response.data; 
    }
}

export const verifyMobileOtp = async (otpData) => {
    try {
     const {data} = await axiosInstance.post("verification/mobile/verifyOtp",otpData,{
        headers:{
            "Content-Type": "application/json",
            "x-api-key":process.env.REACT_APP_AUTH_API_KEY
        }
     })
     return data;
    } catch (error) {
        return error.response.data; 
    }
}


export const captureTimeStamp = async(stampData) => {
    try {
        const {data} = await axiosInstance.post("verification/mobile/clickTimestamp",stampData,{
           headers:{
               "Content-Type": "application/json",
               "x-api-key":process.env.REACT_APP_AUTH_API_KEY
           }
        })
        return data;
       } catch (error) {
           return error.response.data; 
       }
}

export const UploadMandateForm = async(mandateData) => {
    try {
        const {data} = await axiosInstance.post("cis/signedMandate",mandateData,{
           headers:{
               "Content-Type": "application/json",
               "x-api-key":process.env.REACT_APP_AUTH_API_KEY
           }
        })
        return data;
       } catch (error) {
           return error.response.data; 
       }
}
