import React from 'react'
import loader from '../../images/loader.gif'
import './ApiCallLoader.css'

const ApiCallLoader = () => {
  return (
    <div className="api-loader-container">
        <img src={loader} alt="loader"/>
      </div>
  )
}

export default ApiCallLoader
