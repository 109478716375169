import React from 'react'
import loader from '../../images/loader.gif'

const Loader = ({message}) => {
  return (
    <div className="loader-container">
        <img src={loader} alt="loader"/>
       {message && <div className='loader-message-text'>{message}</div>}
      </div>
  )
}

export default Loader