import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import './AgreementSignning.css'
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { VerifyAgreementOtp, getUserStatus, sendAgreementOtp } from "../../action/document";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader/Loader";
import CustomErrorPage from "../CustomErrorPage/CustomErrorPage";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import AgreeImg from '../../images/agreeImg.jpg'
import {BsFillEyeFill,} from 'react-icons/bs'
import {BiSolidDownload} from 'react-icons/bi'
import PaymentDetails from "../PaymentDetails/PaymentDetails";
import NoActionMessageOnlyScreen from "../NoActionMessageOnlyScreen/NoActionMessageOnlyScreen";
import Sidebar from "../Sidebar/Sidebar";
import PNach from "../PNach/PNach";
import NachRegistaration from "../NachRegistaration/NachRegistaration";

let interval;

const AgreementSignning = () => {
  const [otp,setOtp] = useState("");
  const [isOtpSend,setIsOtpSend] = useState(false);
  const [resendStart,setResendStart] = useState("");
  const [pdfBase64,setPdfBase64] = useState("");
  const [loading,setLoading] = useState(true);
  const [showUIToUser,setShowUIToUser] = useState(false);
  const [apiLoader,setApiLoader] = useState(false);
  const [err,setErr] = useState("")
  const params = useParams();
  const [appId,setAppId] = useState(params?.id || "");
  const [transId,setTransId] = useState(params?.transId || "")
  const [mobile,setMobile] = useState();
  const [userName,setUserName] = useState();
  const [redirectUrl,setRedirectUrl] = useState("");
  const [stage,setStage] = useState("");
  const [agreementsignSuccess,setAgreementsignSuccess] = useState(false);
  const [showResendOtpBtn,setShowResendOtpBtn] = useState(false);
  const [showPaymentDetails,setShowPaymentDetails] = useState("");
  const [paymentDetails,setPaymentDetails] = useState({
    advanceEmi:'',
    processingFee:'',
    downPayment:'',
    totalUpfrontAmount:'',
  });
  const [nachFlag,setNachFlag] = useState("");
  const [fileName,setFileName] = useState("");
  const [stageMessage,setStageMessage] = useState("");
  const [succMessBelowSignAgree,setSuccMessBelowSignAgree] = useState("");
  const [emandateId,setEmandateId] = useState("")
  const [nachFormBase64,setNachFormBase64] = useState("");
   const navigate = useNavigate();
  // const transId = "006C40054600tgbWSX";
  const [sidebarStage,setSidebarStage] = useState("");
  const [showNachScreen,setShowNachScreen] = useState(false);

 
//  to get the initial stage and agrrement base64
  useEffect(() => {
    getStatus();
   }, [])
   
   const getStatus = async () => { 
    setApiLoader(true);
    const res = await getUserStatus(appId,transId);
    setLoading(false)
    setApiLoader(false)
    if(res.status === "success"){
      setErr("")
      setPdfBase64(res.metadata.agreementBase64);
      setFileName(res?.metadata?.fileName || "");
      setSidebarStage(res.stageName)
     if(res.stage === "agreement_pending"){
       setShowUIToUser(true);
       setStage("Agreement Signing");
     }else if(res.stage === "payment_pending"){
      setStage("Payment Collection");
      setRedirectUrl(res.url)
      setShowPaymentDetails( agreementsignSuccess ? "short" : "full");
      setPaymentDetails({...paymentDetails,advanceEmi:res.metadata.advance_emi,
        processingFee:res.metadata.processing_fee,
        downPayment:res.metadata.downpayment,
        totalUpfrontAmount: res.metadata.total_upfront_payment,
        // totalUpfrontAmount: "77488",
      })
    
     }
     else if(res.stage === "nach_pending"){
      setStage("Nach Register");
     let localStage = res.stageName;
  
    // if(res.stage === "payment_pending"){
    //   setStage("Payment Collection")
    //   localStage = "Payment Collection"
    // }
      setUserName(res?.metadata?.customer_name || "");
    if(res.metadata?.nach_status === "ENach Not Initiated"){
      setRedirectUrl(res.url);
      // toast(`Redirecting to ${localStage} stage.`)
      //  setTimeout(() => {
      //    window.location.href = res.url;
      //  }, 1000);
      setShowNachScreen(true)
    }
    else if(res.metadata?.nach_status === "ENach Pending" || res.metadata?.nach_status === "PNach Pending"){
      setStageMessage(`Your application is under processing`);
    }
    else if(res.metadata?.nach_status === "ENach Completed" || res.metadata?.nach_status === "PNach Completed"){
      setStageMessage("Thank you for completing process successfully.")
    }
    else if(res.metadata?.nach_status === "ENach Failed"){
      setNachFlag("ENach Failed");
      setEmandateId(res.metadata?.emandate_id || "")
      setNachFormBase64(res.metadata?.pNachBase64Form || "");
    } 
    else if(res.metadata?.nach_status === "PNach Failed"){
      setNachFlag("ENach Failed");
      setEmandateId(res.metadata?.emandate_id || "")
      setNachFormBase64(res.metadata?.pNachBase64Form || "");
    }
  }
  else if(res.stage === "Pre-disbursal"){
    setUserName(res?.metadata?.customer_name || "");
    if(res.metadata.Status_NACH === "rejected" || res.metadata.Status_NACH === null){
      if(res.metadata.nach_status === "PNach Pending" || res.metadata?.nach_status === "ENach Pending"){
        setStageMessage(`Your application is under processing`);
        return
      }
      if(res.metadata.nach_status === "PNach Completed" || res.metadata?.nach_status === "ENach Completed"){
        setStageMessage("Thank you for completing process successfully.")
        return
      }
      setNachFlag("ENach Failed");
      setEmandateId(res.metadata?.emandate_id || "")
      setNachFormBase64(res.metadata?.pNachBase64Form || "");
    }
    else{
      setStageMessage("Thank you for completing process successfully.")
    }
  }
     else if(res.stage && res.url){

       setRedirectUrl(res.url)
       toast(`Redirecting to ${res.stageName} stage.`)
        setTimeout(() => {
          window.location.href = res.url;
        }, 1000);
   
     }
     else if(res.stage){
      setStage(res.stage);
      if(res.stage === "Product Selection" || res.stage === "Select Plan" || res.stage === "KYC Verification" || res.stage === "Select lender"  || res.stage === "Lender's Approval Pending"){
        setStageMessage(`Application is on ${res.stage} stage`)
      }else{
        setStageMessage("Thank you for completing process successfully.")
      }
     }
    }else{
      setErr(res.message)
      toast.error(res.message)
      setSidebarStage(res?.stageName || "")
    }
 }

  // clear the interval when timer ends
  useEffect(()=>{
    if(resendStart === 0){
     clearInterval(interval);
    }
  },[resendStart])

  //  function execute when the enter key is hit
  const hadleEnterKeyDown = () => {

  }

  const handleAgreementSign = async () => {
    const sendData = {
      app_id:appId,
      trans_id:transId,
    }
    clearInterval(interval)
    setApiLoader(true);
    const res = await sendAgreementOtp(sendData);
    setApiLoader(false)
    if(res.status === "success"){
    toast.success(res.message)
    setIsOtpSend(true);
    setResendStart(59);
    setMobile(res.mobile_no);
    setUserName(res.customer_name)
    setShowResendOtpBtn(true)
    interval = setInterval(() => {
      setResendStart(prev =>prev === 0 ? 0 :prev-1)
   }, 1000);
  }else{
    toast.error(res.message);
    setErr(res.message)
  }
}

const handleverifyOtp = async() => {
  const sendData = {
    app_id:appId,
    trans_id:transId,
    otp:otp,
    mobile_no:mobile,
    agreement_base64:pdfBase64,
    customer_name:userName,
    fileName
    // client_ip_address:"111.154.31.247"
  }
    setApiLoader(true)
  const res = await VerifyAgreementOtp(sendData);
  setApiLoader(false)
  if(res.status === "success"){
    toast.success(res.message)
    clearInterval(interval);
    setPdfBase64(res.data.agreementBase64);
    setRedirectUrl(res.data.url)
    setAgreementsignSuccess(true);

    if(res.data.stage === "nach_pending"){
      if(res.data.Status_NACH === "confirmed"){
        setSuccMessBelowSignAgree("Thank you for completing process successfully.")
      }
      setStage("Nach Register")
    }
    if(res.data.stage === "payment_pending"){
      setStage("Payment Collection");
      setShowPaymentDetails("short");
      setPaymentDetails({...paymentDetails,advanceEmi:res.data.advance_emi,
        processingFee:res.data.processing_fee,
        downPayment:res.data.downpayment,
        totalUpfrontAmount: res.data.total_upfront_payment,
      })
    }
    // sessionStorage.setItem("agreement",true);
    // navigate("/success")
  }else{
    toast.error(res.message);
    // setErr(res.message)
  }
}

const handleRedirectToPaymentCollection = async() => {
  // const res = await getPaymentUrl();
 
  setApiLoader(true);
  const res = await getUserStatus(appId,transId);
  setApiLoader(false)
  if(res.status === "success"){
    // setErr("")
    // setPdfBase64(res.metadata.agreementBase64)
   if(res.stage === "agreement_pending"){
     setShowUIToUser(true);
     setStage("Agreement Signing");
   }else if(res.stage === "payment_pending"){
    setStage("Payment Collection");
    // setRedirectUrl(res.url)
    // setShowPaymentDetails("short");
    // setPaymentDetails({...paymentDetails,advanceEmi:res.metadata.advance_emi,
    //   processingFee:res.metadata.processing_fee,
    //   downPayment:res.metadata.downpayment,
    //   totalUpfrontAmount: res.metadata.total_upfront_payment,
    //   // totalUpfrontAmount: "77488",
    // })

    toast.success(`Redirecting to Payment Collection stage`);
    setTimeout(() => {
      window.location.href = res.url;
    }, 1000);
   }
   else if(res.stage === "nach_pending"){
    setStage("Nach Register");
    setUserName(res?.metadata?.customer_name || "");
  if(res.metadata?.nach_status === "ENach Not Initiated"){
    setRedirectUrl(res.url);
    setShowNachScreen(true)
  }
  else if(res.metadata?.nach_status === "ENach Pending" || res.metadata?.nach_status === "PNach Pending"){
    setStageMessage(`Your application is under processing`);
  }
  else if(res.metadata?.nach_status === "ENach Completed" || res.metadata?.nach_status === "PNach Completed"){
    setStageMessage("Thank you for completing process successfully.")
  }
  else if(res.metadata?.nach_status === "ENach Failed"){
    setNachFlag("ENach Failed");
    setEmandateId(res.metadata?.emandate_id || "")
    setNachFormBase64(res.metadata?.pNachBase64Form || "");
  } 
  else if(res.metadata?.nach_status === "PNach Failed"){
    setNachFlag("ENach Failed");
    setEmandateId(res.metadata?.emandate_id || "")
    setNachFormBase64(res.metadata?.pNachBase64Form || "");
  }
}
else if(res.stage === "Pre-disbursal"){
  setUserName(res?.metadata?.customer_name || "");
  if(res.metadata.Status_NACH === "rejected"){
    if(res.metadata.nach_status === "PNach Pending"){
      setStageMessage(`Your application is under processing`);
      return
    }
    setNachFlag("ENach Failed");
    setEmandateId(res.metadata?.emandate_id || "")
    setNachFormBase64(res.metadata?.pNachBase64Form || "");
  }else{
    setStageMessage("Thank you for completing process successfully.")
  }
}
   else if(res.stage && res.url){
     let localStage = ""
    if(res.stage === "nach_pending"){
      // setStage("Nach Register");
      localStage = "Nach Register";
    }
    // setRedirectUrl(res.url)
     toast(`Redirecting to ${localStage?localStage: res.stage} stage.`)
      setTimeout(() => {
        window.location.href = res.url;
      }, 1000);
   }
  }else{
    // setErr(res.message)
    toast.error(res.message)
  }
}



  return (
    loading ? < Loader /> :
    stageMessage ? < NoActionMessageOnlyScreen message={stageMessage}/> :
    showPaymentDetails === "full" ?  
    <PaymentDetails 
    paymentDetails={paymentDetails} 
    showPaymentBtn={true} 
    redirectUrl = {redirectUrl} 
    stage={stage}
    sidebarStage={sidebarStage}
    />           :
    showUIToUser === false && loading === false && err ?
    <CustomErrorPage/> : 
    nachFlag === "ENach Failed" ?
    <PNach  
      nachFlag={nachFlag}
      emandateId ={emandateId}
      nachFormBase64={nachFormBase64}
      stage={stage}
      sidebarStage={sidebarStage}
      transId={transId}
      userName={userName}
      getStatus={getStatus}
    />
    : showNachScreen === true ?
    <NachRegistaration 
    message={"Your Application is on Nach Registaration Stage"}
    url={redirectUrl}
    setStageMessage ={setStageMessage}
    appId={appId}
    transId={transId}
    /> :   
    showUIToUser === true &&
    <>
    <Navbar />
    <div className='side-main-container'>
      <Sidebar
      stage={sidebarStage}
      />
     <div className="main-content" style={{display:"flex",justifyContent:"center"}}>
     <>
      {apiLoader && <ApiCallLoader/>}
      {/* <Navbar/> */}
      <div>
        <div className={showPaymentDetails === "short" ?"mainbody dividescreen" : "mainbody"}>
        <div className="agreement-section">
          {/* for url  */}
        {/* <iframe src={file} height="500" width="700" title="Iframe Example" ></iframe> */}
        {/* for base64 */}
        {/* desktop view */}
        <iframe src={"data:application/pdf;base64,"+pdfBase64} height="500" width="700" title="Iframe Example" ></iframe>

         {/* Mobile view */}
         <div className="agree-cont">
             <img src={AgreeImg} className="agree-img"/>
             <div className="icons-cont">
            <a href={"data:application/pdf;base64,"+pdfBase64} target="_blank" className="agree-icons">
            <BsFillEyeFill size={32} color="#2596be"/>
            </a>
            <a href={"data:application/pdf;base64,"+pdfBase64} className="agree-icons">
             <BiSolidDownload size={32} color="#2596be"/>
            </a>
             </div>
         </div>

        {/* agreement is success then hide all sign and otp screen */}
         {agreementsignSuccess ?  
         <div className="sign-btn-cont">
             {
              showPaymentDetails === "short" && 
              <><h2 className='pd-header'>Payment Details</h2>
        <div className='payment-detail'>

  
        {paymentDetails?.advanceEmi && <div className='pd-key-value'>
    <span className='pd-key'>Advance EMI </span> 
    <span className='pd-value'>₹ {paymentDetails.advanceEmi}</span>
   </div>
}
  {paymentDetails?.processingFee  && <div className='pd-key-value'>
    <span className='pd-key'>Processing Fee</span> 
   <span className='pd-value'>₹ {paymentDetails.processingFee}</span>
   </div>}
   
  {paymentDetails?.downPayment && <div className='pd-key-value'>
    <span className='pd-key'>Down Payment</span>
    <span className='pd-value'>₹ {paymentDetails.downPayment}</span>
    </div>}

  {paymentDetails?.totalUpfrontAmount &&  <div className='pd-key-value'>
    <span className='pd-key'>Total Upfront Payment</span>
    <span className='pd-value'>₹ {paymentDetails.totalUpfrontAmount}</span>
    </div>
}
</div>
</>
}
         { succMessBelowSignAgree ? <h3 className="process-succ-mes">{succMessBelowSignAgree}</h3>  : <button 
         className={apiLoader ?"disable-btn verify-btn pd-payment-btn" :"enable-btn verify-btn pd-payment-btn"}
          onClick={handleRedirectToPaymentCollection}
          disabled={ apiLoader ? true : false }
          >Continue to {stage == "Nach Register" ? "NACH Registration" : stage }</button>}
          </div>
         
         :<div className="sign-btn-cont">
       { !showResendOtpBtn ?   <button 
         className={(resendStart > 0 && resendStart <=59 ) || apiLoader ?"disable-btn verify-btn" :"enable-btn verify-btn"}
          onClick={handleAgreementSign}
          disabled={(resendStart > 0 && resendStart <=59 ) || apiLoader ? true : false }
          >Sign Agreement</button>
          :  
          <p className='resend resendotpsec' ><button className={`resendoTp ${(resendStart > 0 && resendStart <=59) || apiLoader ?"disable-resend":''}`} onClick={handleAgreementSign} disabled={(resendStart > 0 && resendStart <=59) || apiLoader ? true: false}>Resend</button> OTP in 00:{resendStart < 10 ? `0${resendStart}` : resendStart} seconds</p>
          }
         {/* {resendStart > 0 && resendStart <=59 && <p className='resend'>Resend OTP in 00:{resendStart < 10 ? `0${resendStart}` : resendStart} seconds</p>} */}
          </div>}
         {agreementsignSuccess === false && isOtpSend && <div className="otp-cont">
                 <h4 className="otp-sent-text">Enter OTP</h4>
                 <div className="optverify">
                   <OTPInput
                     value={otp}
                     // onChange={(e) => this.setState({otp:e.target.value })}
                     onChange={(value) => setOtp(value)}
                     numInputs={6}
                     shouldAutoFocus
                     inputType="number"
                     renderSeparator={<span></span>}
                     renderInput={(props) => (
                       <input {...props} onKeyDown={hadleEnterKeyDown} />
                     )}
                   />
              
                 </div>
                
                 <button
                 disabled={otp.length < 6 || apiLoader? true : false}
                 className={
                  otp.length < 6 || apiLoader
                  ? "disable-btn verify-btn"
                     : "enable-btn verify-btn"
                   }
                   onClick={handleverifyOtp}
                   >
                 Verify OTP
               </button>
   
               </div>}

    </div>
        </div>
      </div>
    </>
     </div>
    </div>
    </>
  );
};

export default AgreementSignning;
