import React from 'react'
import './NoActionMessageOnlyScreen.css'
import Navbar from '../Navbar/Navbar'

const NoActionMessageOnlyScreen = ({message}) => {
  return (
   <>
      <Navbar/>
    <div className='message-only-conatiner'>
      <h2>{message}</h2>
    </div>
   </>
  )
}

export default NoActionMessageOnlyScreen