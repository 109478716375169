import React, { useEffect, useState } from 'react'
import Loader from '../Loader/Loader';
import CustomErrorPage from '../CustomErrorPage/CustomErrorPage';
import SuccessKyc from '../SucessKyc/SuccessKyc';
import { useParams, useSearchParams } from 'react-router-dom';
import { getUserStatus } from '../../action/document';
import { toast } from 'react-toastify';
import NoActionMessageOnlyScreen from '../NoActionMessageOnlyScreen/NoActionMessageOnlyScreen';

const PaymentSuccess = () => {
    const [loading,setLoading] = useState(true);
    const [showUIToUser,setShowUIToUser] = useState(false);
    const [err,setErr] = useState("");
    const [nachStatus,setNachStatus] = useState("");
    const [nachStatusforPnach,setNachStatusforPnach] = useState("");
    // with query params
    const [searchParams, setSearchParams] = useSearchParams();
    const [paidStatus,setPaidStaus] = useState(searchParams.get("razorpay_payment_link_status"))
    // with params
    const params = useParams();
    const [appId,setAppId] = useState(params?.id || "");
    const [transId,setTransId] = useState(params?.transId || "");
    const [stage,setStage] = useState("")
    const [rediectUrl,setRediectUrl] = useState("");
    const [stageMessage,setStageMessage] = useState("");
    const [showNextStageButton,setShowNextStageButton] = useState(true);
    // call api and check status
    useEffect(() => {
          getStatus();
       }, [])

       const getStatus = async () => { 
         setLoading(true);
         const res = await getUserStatus(appId,transId);
         setLoading(false)
         if(res.status === "success"){
           setErr("")
           setNachStatus(res?.metadata?.Status_NACH || "");
           setNachStatusforPnach(res?.metadata?.nach_status || "")
          if(res.stage === "agreement_pending"){
            setStage("Agreement Signing");
            setRediectUrl(res.url)
          }else if(res.stage === "payment_pending"){
            setStage("Payment Collection");
            setRediectUrl(res.url);
          }else if(res.stage === "nach_pending"){
            setStage("Nach Register");
            setRediectUrl(res.url);
          }else if(res.stage === "Product Selection" || res.stage === "Select Plan" || res.stage === "KYC Verification" || res.stage === "Select lender"  || res.stage === "Lender's Approval Pending"){
            setStageMessage(`Application is on ${res.stage} stage`)
          }else{
               setStageMessage("Thank you for completing process successfully. ")
          }
         }else{
          //  setErr(res.message)
          //  toast.error(res.message);

          if(res?.stage === "agreement_pending"){
            // setShowUIToUser(true);
            setStage("Agreement Signing");
            setRediectUrl(res?.url)
          }
          if(res?.stage === "payment_pending"){
            setStage("Payment Collection");
            setRediectUrl(res?.url);
         }
         }
      }


      // const checkNachStage = async () => {
        
      // }

  return (
        loading ? <Loader message={"Wait we are fetching your data"}/> :
        stageMessage ? < NoActionMessageOnlyScreen message={stageMessage}/> :
        nachStatus === "confirmed" && stage !== "Payment Collection" ? < NoActionMessageOnlyScreen message="Thank you for completing process successfully."/>:
        err ? <CustomErrorPage message="Payment Failed" /> :
        <SuccessKyc 
         message ="Your payment has been successfully processed" 
         showNextStageButton={showNextStageButton}
         setShowNextStageButton={setShowNextStageButton} 
         stage={stage} 
         rediectUrl={rediectUrl} 
         setNachStatus={setNachStatus} 
         nachStatus ={nachStatus}
         appId={appId} 
         transId={transId}
         setStageMessage={setStageMessage}
         nachStatusforPnach={nachStatusforPnach}
         getStatus={getStatus}
         />
  )
}

export default PaymentSuccess