import React from 'react'

const CustomErrorPage = ({message}) => {
  return (
    <div className="loader-container">
      {message? message :"Failed to load data"}
      </div>
  )
}

export default CustomErrorPage