import React from 'react'
import "./Navbar.css"
import logo from "../../images/eduvanzlogo.png"

const Navbar = () => {
  return (
    <div className='navbar'>
    <div className='logo-container'>
       <img src={logo} alt="logo" className='wizr-logo'/>
    </div>
  </div>
  )
}

export default Navbar