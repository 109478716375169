import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar';
// import '/PaymentDetails.css'
import { toast } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar';
import "./PNach.css"
import ApiCallLoader from '../ApiCallLoader/ApiCallLoader';
import { UploadMandateForm } from '../../action/document';

const PNach = ({stage,redirectUrl,sidebarStage,transId,emandateId,nachFormBase64,userName,getStatus}) => {
    const inputFile = useRef(null);
    const [apiLoader,setApiLoader] = useState(false);

    if(nachFormBase64){
        
    }

    useEffect(() => {
        if(nachFormBase64){
            displayBase64File(nachFormBase64)
        }
    },[nachFormBase64])
  
    const handleUploadClick = () => {
        inputFile.current.value = "";
        inputFile.current.click();
    }

    const handleFileChange = (e) => {
         const file = e.target.files[0];
         if(!(file.name.split(".").pop() === "jpg" || file.name.split(".").pop() === "jpeg" || file.name.split(".").pop() === "png")){
            toast.error("Please upload the jpg, jpeg and png file only.");
            return;
         }
         const reader = new FileReader();
         reader.onload = () => {
         const base64 = reader.result.split(',')[1];
         handleUploadFile(base64,file.name.split(".").pop());
    };
    reader.readAsDataURL(file);
    }

    const handleDownloadFile = () => {
        const linkSource = `data:application/pdf;base64,${nachFormBase64}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = userName ? `PNACH Form - ${userName}_${new Date().toJSON().slice(0,10)}`: `PNACH Form - ${new Date().toJSON().slice(0,10)}`;
        downloadLink.click();
    }

    const handleUploadFile = async(file,fileType) => {
        const data = {
                transaction_id:transId,
                mandate_id:emandateId,
                file_data:file,
                file_type:fileType,
            }
              setApiLoader(true)
            const res = await UploadMandateForm(data);
            setApiLoader(false)
            if(res.status === "success"){
                 toast.success("Form uploaded successfully");
                 getStatus();
            }else{
                toast.error(res?.error?.errorMessage || "Something went wrong")
            }
    }
    

    function displayBase64File(base64String) {
        // Convert Base64 to Blob
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' }); // Change the type accordingly
    
        // Create URL for the Blob
        const blobUrl = URL.createObjectURL(blob);
    
        // Set src attribute of the iframe to the Blob URL
        const iframe = document.getElementById('fileIframe');
        iframe.src = blobUrl;
        
    }
    
    
  return (
    <>
    <Navbar />
    <div className='side-main-container'>
      <Sidebar
      stage={sidebarStage}
      />
     <div className="main-content">
     <>
     {apiLoader && <ApiCallLoader/>}
        {/* <Navbar/> */}
    <div className='pnach-container'>
            <h2 className='pd-header'>Physical NACH</h2>
            <div className='pnach-content-box'>
             <div className='pnach-instructions'>
                <div className='pnach-htext'><b>Instructions for Submitting a Mandate Form</b></div>
                <ul>
                    <li>Download and print the pre-filled application form.</li>
                    <li>Signing the form:
                        <ul>
                            <li>Sign above your printed name.</li>
                            <li>Sign in only one place.</li>
                            <li>Your signature must match your bank account signature.</li>
                            <li>Use a dark ink pen for clear visibility in the photo.</li>
                        </ul>
                    </li>
                    <li>Taking a photo of the form:
                    <ul>
                            <li>Ensure the entire mandate form, including all black borders, is within the photo.</li>
                            <li>The photo must be clear and the text fully readable.</li>
                           
                        </ul>
                    </li>
                    <li><b>Important</b>: Your mandate might be rejected if you don't follow these instructions.</li>
                    <li className='text-red'>Kindly upload scanned PNACH Form in jpg, jpeg or png format.</li>
                </ul>
             </div>
            {/* <iframe src={"data:application/pdf;base64,"+pdfBase64} height="500" width="700" title="Iframe Example" ></iframe> */}
            <div>
            {/* <iframe src={"data:application/pdf;base64,"+nachFormBase64} height="300" width="600" title="Iframe Example" ></iframe> */}
            <iframe height="300" width="600" title="Iframe Example" id='fileIframe' ></iframe>
            <div className='pnach-btns'>
    <button 
className="enable-btn verify-btn pd-payment-btn"
onClick={handleDownloadFile}
>Download File</button>
<button 
className="enable-btn verify-btn pd-payment-btn"
onClick={handleUploadClick}
>Upload File
<input type='file' onChange={handleFileChange} accept=".jpg, .jpeg, .png" id='file' ref={inputFile} style={{display: 'none'}}/>
</button>
    </div>
            </div>



   
            </div>
        </div>
    </>
     </div>
    </div>
    </>
  )
}

export default PNach