import logo from './logo.svg';
import './App.css';
import Home from './components/Home/Home';
import Navbar from './components/Navbar/Navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import SuccessKyc from './components/SucessKyc/SuccessKyc';
import AgreementSignning from './components/AgreementSignning/AgreementSignning';
import Vkyc from './components/Vkyc/Vkyc';
import PaymentSuccess from './components/PaymentSuccess/PaymentSuccess';
import NachSuccess from './components/NachSuccess/NachSuccess';
import VerifyMobile from './components/VerifyMobile/VerifyMobile';

const router = createBrowserRouter([
  {
    path: "/cis/:id",
    element: <Home/>,
  },
  {
    path: "/success",
    element: <SuccessKyc/>,
  },
  {
    path: "/cis/:id/:transId",
    element: <AgreementSignning/>,
  },
  {
    path: "/vkyc/:id",
    element: <Vkyc/>,
  },
  {
    path: "/payment/:id/:transId",
    element: <PaymentSuccess/>,
  },
  {
    path: "/nach/:id",
    element: <NachSuccess/>,
  },
  {
    path: "/verifymob/:id",
    element: <VerifyMobile/>,
  },
]);


function App() {
  return (
    <div className="App">
       <RouterProvider router={router} />
      <ToastContainer/>
    </div>
  );
}

export default App;
