const stagesList = 
[
"Registration Incomplete",
"Application Pending",
"Bank Statement Pending",
"KYC Pending",
"Other Document Pending",
"Additional Document Requested",
"Higher Limit Application Initiated",
"Bureau Pull Failed",
"Bank Account Details/ Penny Drop Pending",
"Higher Limit Application Initiated",
"Document Verification Pending",
"Pre-Assessment FI Initiated",
"Pre-Assessment FI Pending",
// "Approval Pending - Pre-Assessment FI Successful",
// "Approval Pending - Pre-Assessment FI Failed",
"Approval Pending",
"Under Referral",
"Post Approval Deviation Pending",
"Higher Limit Approval Pending",
"Pre-Approval FI Initiated",
"Pre-Approval FI Pending",
"Whitelisting Pending",
"Pre-Account Activation",
"Account Active",
"Closed",
"Product Selection",
"Select Plan",
"KYC Verification",
"Select Lender",

"Agreement generation/Signoff",
"Payment Collection",
"NACH Registration",
"Pre-Disbursal",
"DRF Required",
"Lender's Disbursal Pending",
"Order Delivery Pending",
"Ready to Disburse",
"Loan Disbursed",
"Closed"
]

export const getActiveStageIndex = (stage) => {
 const index = stagesList.findIndex(ele => ele === stage);
 if(index){
    return index;
 }else{
    return -1;
 }
}

export const getStageListWithIndex = (list) => {
    const data = [];
    list.forEach(ele => {
        const index = stagesList.findIndex(item => item === ele.stage);
        data.push({...ele,index:index})
    })
    return data;
}