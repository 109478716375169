import React, { useEffect, useState } from "react";
import "./Home.css";
import OtpInput from 'react-otp-input';



import { toast } from "react-toastify";
import { VerifyAdharNumber, VerifyAdharOtp, getUserStatus } from "../../action/document";
import Navbar from "../Navbar/Navbar";
import { useParams, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CustomErrorPage from "../CustomErrorPage/CustomErrorPage";
import Loader from "../Loader/Loader";
import ApiCallLoader from "../ApiCallLoader/ApiCallLoader";
import NoActionMessageOnlyScreen from "../NoActionMessageOnlyScreen/NoActionMessageOnlyScreen";
import Sidebar from "../Sidebar/Sidebar";

let interval;

const Home = () => {
  const [disableAdhar, setDisableAdhar] = useState(true);
  const [aadharno, setAadharNo] = useState("");
  const [otp,setOtp] = useState("");
  const [consent,setConsent] = useState(false);
  const [shareCode,setShareCode] = useState("");
  const [isAadharVerified,setIsAadharVerified] = useState(false);
  const [resendStart,setResendStart] = useState("");
  const [showResendOtpText,setShowResendOtpText] = useState(false);
  const [transactionId,setTransactionId] = useState("");
  const [loading,setLoading] = useState(true);
  const [showUIToUser,setShowUIToUser] = useState(false);
  const [err,setErr] = useState("");
  const [showResendOtpBtn,setShowResendOtpBtn] = useState(false);
  const [stageMessage,setStageMessage] = useState("")
  // with query params
  // const [searchParams, setSearchParams] = useSearchParams();
  // const [appId,setAppId] = useState(searchParams.get("appId"))
//   with params
  const params = useParams();
  const [appId,setAppId] = useState(params?.id || "");
  const [apiLoader,setApiLoader] = useState(false);
  const [sidebarStage,setSidebarStage] = useState("")


  const navigate = useNavigate();

  // get user stage
  useEffect(() => {
   const getStatus = async () => {
        setApiLoader(true);
      const res = await getUserStatus(appId);
      setApiLoader(false)
      setLoading(false)
      if(res.status === "success"){
        setErr("")
        setSidebarStage(res.stageName)
       if(res.stage === "okyc_pending"){
         setShowUIToUser(true);
       }else if(res.status && res.url){
         toast(`Redirecting to ${res.stage} stage.`)
          setTimeout(() => {
            window.location.href = res.url;
          }, 1000);
       }else{
        // Your application is under process
        setStageMessage("Your application is under process")
       }
      }else{
        setErr(res.message)
        toast.error(res.message)
        setSidebarStage(res?.stageName || "")
      }
   }
   getStatus();
  }, [])
  
  
  // clear the interval when timer ends
  useEffect(()=>{
     if(resendStart === 0){
      setDisableAdhar(false);
      clearInterval(interval);
      setShowResendOtpText(false)
     }

  //    return () => {
  //     clearInterval(interval)
  // } 
  },[resendStart])

  const handleAadharChange = (e) => {
    const value = e.target.value;
    if(value === ""){
      setAadharNo(value);
      setDisableAdhar(true);
    }
    var numbers = /^[0-9]+$/;
    if (!value.match(numbers)) {
      if (value === "") setAadharNo("");
      return;
    }
    setAadharNo(value);
    if (value && value.length === 12) {
      setDisableAdhar(false);
    } else {
      setDisableAdhar(true);
    }
  };

  const handleverifyAdhar = async () => {
    const adharData = {
      consent,
      reference_id: appId,
      aadhaar_number: Number(aadharno),
      // aadhaar_number: aadharno,
      purpose: "for KYC verification",
    }
       setApiLoader(true)
    const res = await VerifyAdharNumber(adharData);
    setApiLoader(false)
    if(res.status === "success"){
      setTransactionId(res?.data?.decentroTxnId || "")
      toast.success(res.message);
      setResendStart(59)
      setIsAadharVerified(true)
      setDisableAdhar(true)
      setShowResendOtpText(true);
      setShowResendOtpBtn(true)
      interval = setInterval(() => {
        setResendStart(prev =>prev === 0 ? 0 :prev-1)
     }, 1000);
    }else{
      toast.error(res.message)
    }
  };

  const handleverifyOtp = async () => {
    const adharData = {
      reference_id: appId,
      transaction_id: transactionId,
      otp,
      consent,
      purpose: "for KYC verification",
      share_code: shareCode,
      generate_pdf: true,
      generate_xml: true,
  }
      setApiLoader(true)
    const res = await VerifyAdharOtp(adharData);
       setApiLoader(false)
    if(res.status === "success"){
      toast.success(res.data.message)
      setDisableAdhar(false);
      clearInterval(interval);
      setShowResendOtpText(false)
      navigate("/success")
    }else{
      toast.error(res.message)
    }
    // success
      // toast.success("Thank you for completing Aadhaar verification process")
      // navigate("/success")
      // setDisableAdhar(false);
      // clearInterval(interval);
      // setShowResendOtpText(false)
  };

  const hadleEnterKeyDown = (e) => {
      if (e.keyCode == 13 && otp.length === 6) {
          // alert("do verify otp")
  }
  }

  return (
      loading ? 
       <Loader/> :
       stageMessage ? < NoActionMessageOnlyScreen message={stageMessage}/> :
        showUIToUser === false && loading === false && err ?
        <CustomErrorPage/> :
        showUIToUser === true &&
        <>
    <Navbar />
    <div className='side-main-container'>
      <Sidebar
      stage={sidebarStage}
      />
     <div className="main-content">
     <>
        {apiLoader && <ApiCallLoader/>}
        {/* <Navbar/> */}
         <div className="mainbody">
           <div className="card-container">
   
             {/* adhar no input and verify conatner start */}
             <div className="addharno-container">
               <div className="adhar-no-label">Aadhaar Number</div>
               <div className="adhar-input-cont">
                 <input
                   className="adhar-input"
                   type="text"
                   value={aadharno}
                   onChange={handleAadharChange}
                   placeholder="Enter the aadhaar number"
                   maxLength={12}
                 />
               </div>
                <div className="adhar-consent">
                  <input type="checkbox" value={consent} onChange={(e)=> setConsent(e.target.checked)}/>
                  <p>Consent to send OTP</p>
                </div>
              <div className="btn-resend-cont">
              <button
                //  disabled={!disableAdhar && consent  ? false : true}
                 disabled={disableAdhar || apiLoader || consent === false ? true : false}
                 className={
                  disableAdhar || apiLoader || consent === false
                     ? "disable-btn verify-btn" 
                     :"enable-btn verify-btn"
                   }
                   onClick={handleverifyAdhar}
                   >
                 {showResendOtpBtn ?"Resend OTP":"Send OTP"}
               </button>
              {showResendOtpText && <p className='resend'>Resend OTP in 00:{resendStart < 10 ? `0${resendStart}` : resendStart} seconds</p>}
   
              </div>
             </div>
             {/* adhar no input and verify conatner end */}
   
             {/* otp and  share code ui start */}
           { isAadharVerified &&  <div className="otp-share-container">
               <div className="otp-cont">
                 <h4 className="otp-sent-text">Enter OTP</h4>
                 <div className="optverify">
                   <OtpInput
                     value={otp}
                     // onChange={(e) => this.setState({otp:e.target.value })}
                     onChange={(value) => setOtp(value)}
                     numInputs={6}
                     shouldAutoFocus
                     inputType="number"
                     renderSeparator={<span></span>}
                     renderInput={(props) => (
                       <input {...props} onKeyDown={hadleEnterKeyDown} />
                     )}
                   />
              
                 </div>
                 <div className="share-code-cont">
                   <label className="adhar-no-label">Share code</label>
                   <input type="text" 
                   value={shareCode}
                   onChange={(e)=>setShareCode(e.target.value)}
                   placeholder="Enter the share code"
                   maxLength={4}
                   />
                 </div>
                 <button
                 disabled={otp.length < 6 || shareCode.length < 4 || apiLoader ? true : false}
                 className={
                  otp.length < 6 || shareCode.length < 4 || apiLoader
                    ?"disable-btn verify-btn"
                     : "enable-btn verify-btn"
                   }
                   onClick={handleverifyOtp}
                   >
                 Verify Aadhaar
               </button>
   
               </div>
               {/* <div></div> */}
             </div>}
             {/* otp and  share code ui end */}
   
           </div>
         </div>
         {/* <div className="footer"></div> */}
       </>
     </div>
    </div>
    </>
  );
};

export default Home;
