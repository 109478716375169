import React, { useEffect, useState } from 'react'
import Loader from '../Loader/Loader';
import CustomErrorPage from '../CustomErrorPage/CustomErrorPage';
import SuccessKyc from '../SucessKyc/SuccessKyc';
import { useParams, useSearchParams } from 'react-router-dom';
import { getUserStatus } from '../../action/document';
import { toast } from 'react-toastify';

const NachSuccess = () => {
    const [loading,setLoading] = useState(false);
    const [showUIToUser,setShowUIToUser] = useState(false);
    const [err,setErr] = useState("");

    // with query params
    // const [searchParams, setSearchParams] = useSearchParams();
    // const [status,setStatus] = useState(searchParams.get("status"))
  //   with params
    // const params = useParams();
    // const [appId,setAppId] = useState(params?.id || "");

    // call api and check status
    // useEffect(() => {
    //     const getStatus = async () => { 
    //        const res = await getUserStatus(appId,"006C40000000ry");
    //        setLoading(false)
    //        if(res.status === "success"){
    //          setErr("")
    //         if(res.stage === "agreement"){
    //           setShowUIToUser(true);
    //         }else if(res.status && res.url){
    //           toast(`Redirecting to ${res.stage} stage.`)
    //            setTimeout(() => {
    //              window.location.href = res.url;
    //            }, 1000);
    //         }
    //        }else{
    //          setErr(res.message)
    //          toast.error(res.message)
    //        }
    //     }
    //     getStatus();
    //    }, [])
  
  return (
        loading ? <Loader/> :
        err  ? <CustomErrorPage message="Nach Failed"/> :
        <SuccessKyc message ="Your Nach has been done successfully" />
  )
}

export default NachSuccess